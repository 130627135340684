button {
  background: none;
  border: none;
  cursor: pointer;
}

.transition-all {
  transition: all 0.3s ease-in-out;
}

/* El contenido principal tiene una transición de margen cuando el menú está abierto */
.ml-64 {
  margin-left: 16rem; /* 64px en Tailwind (el tamaño del menú abierto) */
}

.ml-16 {
  margin-left: 4rem; /* 16px en Tailwind (el tamaño del menú cerrado) */
}