import { Routes } from '@angular/router';
import { MesaRegalosLandingComponent } from './pages/mesa-regalos-landing/mesa-regalos-landing.component';

export const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },


    {
        path: 'home',
        loadComponent: () => import('./pages/home/home.component')
            .then((a) => a.HomeComponent)
    },
    {
        path: 'configurar-descuentos',
        loadComponent: () => import('./pages/configurar-descuentos/configurar-descuentos.component')
            .then((confd) => confd.ConfigurarDescuentosComponent)
    },
    {
        path: 'configurar-slider',
        loadComponent: () => import('./pages/configurar-slider/configurar-slider.component')
            .then((confsl) => confsl.ConfigurarSliderComponent)
    },
    {
        path: 'configurar-nueva-categorias',
        loadComponent: () => import('./pages/configurar-categorias/configurar-categorias.component')
            .then((confcats) => confcats.ConfigurarCategoriasComponent)
    },
    {
        path: 'configurar-nuevo-producto',
        loadComponent: () => import('./pages/configurar-nuevo-producto/configurar-nuevo-producto.component')
            .then((confnvp) => confnvp.ConfigurarNuevoProductoComponent)
    },
    {
        path: 'configurar-nuevo-producto-imagenes/:id_product',
        loadComponent: () => import('./pages/configurar-nuevo-producto-imagenes/configurar-nuevo-producto-imagenes.component')
            .then((confnvpsssw) => confnvpsssw.ConfigurarNuevoProductoImagenesComponent)
    },
    {
        path: 'configurar-editar-producto',
        loadComponent: () => import('./pages/configurar-editar-producto/configurar-editar-producto.component')
            .then((configsapefb) => configsapefb.ConfigurarEditarProductoComponent)
    },
    {
        path: 'configurar-agregar-color',
        loadComponent: () => import('./pages/configurar-agregar-colores/configurar-agregar-colores.component')
            .then((confcolor) => confcolor.ConfigurarAgregarColoresComponent)
    },
    {
        path: 'configurar-editar-atributo/:id_attribute_group',
        loadComponent: () => import('./pages/configurar-editar-atributo/configurar-editar-atributo.component')
            .then((confeditatri) => confeditatri.ConfigurarEditarAtributoComponent)
    },
    {
        path: 'configurar-producto-con-categoria',
        loadComponent: () => import('./pages/configurar-categoria-aproducto/configurar-categoria-aproducto.component')
            .then((confcatprod) => confcatprod.ConfigurarCategoriaAProductoComponent)
    },
    // {
    //     path: 'login',
    //     loadComponent: () => import('./pages/login/login.component')
    //         .then((b) => b.LoginComponent)
    // },
    // {
    //     path: 'registro',
    //     loadComponent: () => import('./pages/modal-registro/modal-registro.component')
    //         .then((b) => b.ModalRegistroComponent)
    // },
    // {
    //     path: 'recuperar-cuenta',
    //     loadComponent: () => import('./pages/modal-recuperar/modal-recuperar.component')
    //         .then((b) => b.ModalRecuperarComponent)
    // },
    // {
    //     path: 'cart',
    //     loadComponent: () => import('./pages/cart/cart.component')
    //         .then((c) => c.CartComponent)
    // },
    // {
    //     path: 'blog',
    //     loadComponent: () => import('./pages/blogsito/blogsito.component')
    //         .then((d) => d.BlogsitoComponent)
    // },
    // {
    //     path: 'articulo-blog/:id_blog',
    //     loadComponent: () => import('./pages/articulo-blog/articulo-blog.component')
    //         .then((e) => e.ArticuloBlogComponent)
    // },
    // {
    //     path: 'contacto',
    //     loadComponent: () => import('./pages/contacto/contacto.component')
    //         .then((f) => f.ContactoComponent)
    // },
    // {
    //     path: 'cursos-gratis',
    //     loadComponent: () => import('./pages/cursos-gratis/cursos-gratis.component')
    //         .then((g) => g.CursosGratisComponent)
    // },
    // {
    //     path: 'distribuidor',
    //     loadComponent: () => import('./pages/distribuidor/distribuidor.component')
    //         .then((h) => h.DistribuidorComponent)
    // },
    // {
    //     path: 'lidepuntos',
    //     loadComponent: () => import('./pages/lidepuntos/lidepuntos.component')
    //         .then((i) => i.LidepuntosComponent)
    // },
    // {
    //     path: 'compra-y-recoge',
    //     loadComponent: () => import('./pages/compra-y-recoge/compra-y-recoge.component')
    //         .then((i) => i.CompraYRecogeComponent)
    // },
    // {
    //     path: 'conoce-mas-de-varali',
    //     loadComponent: () => import('./pages/conoce-mas-de-varali/conoce-mas-de-varali.component')
    //         .then((i) => i.ConoceMasDeVaraliComponent)
    // },
    // {
    //     path: 'conoce-mas-de-luminos',
    //     loadComponent: () => import('./pages/conoce-mas-de-luminos/conoce-mas-de-luminos.component')
    //         .then((i) => i.ConoceMasDeLuminosComponent)
    // },
    // {
    //     path: 'rally',
    //     loadComponent: () => import('./pages/rally/rally.component')
    //         .then((i) => i.RallyComponent)
    // },
    // {
    //     path: 'clases-creativas',
    //     loadComponent: () => import('./pages/clases-creativas/clases-creativas.component')
    //         .then((i) => i.ClasesCreativasComponent)
    // },
    // {
    //     path: 'especificaciones-plotters-cricut',
    //     loadComponent: () => import('./pages/especificaciones-plotters-cricut/especificaciones-plotters-cricut.component')
    //         .then((i) => i.EspecificacionesPlottersCricutComponent)
    // },
    // {
    //     path: 'codigo-qr',
    //     loadComponent: () => import('./pages/codigo-qr/codigo-qr.component')
    //         .then((i) => i.CodigoQrComponent)
    // },
    // {
    //     path: 'faq',
    //     loadComponent: () => import('./pages/faq/faq.component')
    //         .then((j) => j.FaqComponent)
    // },
    // {
    //     path: 'nosotros',
    //     loadComponent: () => import('./pages/nosotros/nosotros.component')
    //         .then((k) => k.NosotrosComponent)
    // },
    // {
    //     path: 'resetPassword',
    //     loadComponent: () => import('./pages/reseteo-password/reseteo-password.component')
    //         .then((l) => l.ReseteoPasswordComponent)
    // },
    // {
    //     path: 'venta/:ov',
    //     loadComponent: () => import('./pages/resumen-venta/resumen-venta.component')
    //         .then((n) => n.ResumenVentaComponent)
    // },
    // {
    //     path: 'software-de-equipos',
    //     loadComponent: () => import('./pages/software-de-equipos/software-de-equipos.component')
    //         .then((o) => o.SoftwareDeEquiposComponent)
    // },
    // {
    //     path: 'paquetes/:id_product-:sku',
    //     loadComponent: () => import('./pages/descripcion-paquetes/descripcion-paquetes.component')
    //         .then((p) => p.DescripcionPaquetesComponent)
    // },
    // {
    //     path: 'catalogo/:id_categoria/:link_rewrite',
    //     loadComponent: () => import('./pages/catalogo/catalogo.component')
    //         .then((r) => r.CatalogoComponent)
    // },
    // {
    //     path: 'categoria/:id_parent/:link_rewrite',
    //     loadComponent: () => import('./pages/categorias/categorias.component')
    //         .then((s) => s.CategoriasComponent)
    // },
    // {
    //     path: 'paquetes',
    //     loadComponent: () => import('./pages/categoria-por-paquetes/categoria-por-paquetes.component')
    //         .then((t) => t.CategoriaPorPaquetesComponent)
    // },
    // {
    //     path: 'catalogo-paquetes/:id_categoria/:link_rewrite',
    //     loadComponent: () => import('./pages/catalogo-paquetes/catalogo-paquetes.component')
    //         .then((u) => u.CatalogoPaquetesComponent)
    // },
    // {
    //     path: 'categorias',
    //     loadComponent: () => import('./pages/tipos-categorias/tipos-categorias.component')
    //         .then((v) => v.TiposCategoriasComponent)
    // },
    // {
    //     path: 'checkout',
    //     loadComponent: () => import('./pages/checkout/checkout.component')
    //         .then((w) => w.CheckoutComponent)
    // },
    // {
    //     path: 'perfil/:rec',
    //     loadComponent: () => import('./pages/perfil/perfil.component')
    //         .then((ww) => ww.PerfilComponent)
    // },
    // {
    //     path: 'wishlist',
    //     loadComponent: () => import('./pages/wishlist/wishlist.component')
    //         .then((z) => z.WishlistComponent)
    // },
    // {
    //     path: 'promociones-cricut',
    //     loadComponent: () => import('./pages/categoria-promociones-cricut/categoria-promociones-cricut.component')
    //         .then((ht) => ht.CategoriaPromocionesCricutPage)
    // },
    // {
    //     path: 'formulario-expo',
    //     loadComponent: () => import('./pages/formulario-expo/formulario-expo.component')
    //         .then((re) => re.FormularioExpoComponent)
    // },
    // {
    //     path: 'google-search/:busqueda',
    //     loadComponent: () => import('./pages/google-search/google-search.component')
    //         .then((gs) => gs.GoogleSearchComponent)
    // },
    // {
    //     path: 'reviews',
    //     loadComponent: () => import('./pages/reviews/reviews.component')
    //         .then((zzss) => zzss.ReviewsComponent)
    // },
    // {
    //     path: 'direcciones',
    //     loadComponent: () => import('./pages/administra-direcciones/administra-direcciones.component')
    //         .then((fgju) => fgju.AdministraDireccionesComponent)
    // },
    // {
    //     path: 'resumen-compra',
    //     loadComponent: () => import('./pages/resumen-compra/resumen-compra.component')
    //         .then((iiookk) => iiookk.ResumenCompraComponent)
    // },
    // {
    //     path: '840-brother',
    //     loadComponent: () => import('./pages/brotha/brotha.component')
    //         .then((brotha) => brotha.BrothaComponent)
    // },
    // {
    //     path: 'mi-perfil',
    //     loadComponent: () => import('./pages/mi-perfil/mi-perfil.component')
    //         .then((perfilsito) => perfilsito.MiPerfilComponent)
    // },
    // {
    //     path: 'facturacion',
    //     loadComponent: () => import('./pages/facturacion/facturacion.component')
    //         .then((factur) => factur.FacturacionComponent)
    // },
    // {
    //     path: 'mesa-regalos/:id_customer',
    //     loadComponent: () => import('./pages/mesa-regalos/mesa-regalos.component')
    //         .then((mesa) => mesa.MesaRegalosComponent)
    // },
    // {
    //     path: 'mesa-regalos-caja/:id_customer',
    //     loadComponent: () => import('./pages/mesa-regalos-caja/mesa-regalos-caja.component')
    //         .then((mesacaja) => mesacaja.MesaRegalosCajaComponent)
    // },
    // {
    //     path: 'mesa-regalos-conf',
    //     loadComponent: () => import('./pages/mesa-regalos-conf/mesa-regalos-conf.component')
    //         .then((mesaconf) => mesaconf.MesaRegalosConfComponent)
    // },
    // {
    //     path: 'mesa-regalos-landing',
    //     loadComponent: () => import('./pages/mesa-regalos-landing/mesa-regalos-landing.component')
    //         .then((mesaLand) => mesaLand.MesaRegalosLandingComponent)
    // },
    // {
    //     path: 'mesa-regalos-principal',
    //     loadComponent: () => import('./pages/mesa-regalos-principal/mesa-regalos-principal.component')
    //         .then((mesaPrinc) => mesaPrinc.MesaRegalosPrincipalComponent)
    // },
    // {
    //     path: 'mesa-regalos-registro',
    //     loadComponent: () => import('./pages/mesa-regalos-registro/mesa-regalos-registro.component')
    //         .then((mesaRegis) => mesaRegis.MesaRegalosRegistroComponent)
    // },
    // {
    //     path: 'mesa-escoge-regalos',
    //     loadComponent: () => import('./pages/mesa-regalos-elegir/mesa-regalos-elegir.component')
    //         .then((mesaElige) => mesaElige.MesaRegalosElegirComponent)
    // },
    // {
    //     path: 'mesa-regalos-tipos',
    //     loadComponent: () => import('./pages/mesa-regalos-tipos/mesa-regalos-tipos.component')
    //         .then((mesaTipos) => mesaTipos.MesaRegalosTiposComponent)
    // },
    // {
    //     path: 'herramienta-productos-relacionados',
    //     loadComponent: () => import('./pages/productos-relacionados-tool/productos-relacionados-tool.component')
    //         .then((prorelca) => prorelca.ProductosRelacionadosToolComponent)
    // },
    // {
    //     path: 'mesa-regalos-tipo/:id',
    //     loadComponent: () => import('./pages/mesa-regalos-tipo/mesa-regalos-tipo.component')
    //         .then((mesaTipo) => mesaTipo.MesaRegalosTipoComponent)
    // },
    // {
    //     path: 'mesa-regalos-construct',
    //     loadComponent: () => import('./pages/mesa-regalos-construct/mesa-regalos-construct.component')
    //         .then((mesaConstruct) => mesaConstruct.MesaRegalosConstructComponent)
    // },
    // {
    //     path: 'creative-gift',
    //     loadComponent: () => import('./pages/creative-gift/creative-gift.component')
    //         .then((creativooooo) => creativooooo.CreativeGiftComponent)
    // },
    // {
    //     path: 'clases-creativas',
    //     loadComponent: () => import('./pages/clases-creativas/clases-creativas.component')
    //     .then((clasesssss) => clasesssss.ClasesCreativasComponent )
    // },

    // {
    //     path: 'mesa-regalos-ver',
    //     loadComponent: () => import('./pages/mesa-regalos-ver/mesa-regalos-ver.component')
    //         .then((mesaVer) => mesaVer.MesaRegalosVerComponent)
    // },
    // {
    //     path: 'ejecutivoskam',
    //     loadComponent: () => import('./pages/experiencia-compra/experiencia-compra.component')
    //         .then((expcompra) => expcompra.ExperienciaCompraComponent)
    // },

    // {
    //     path: 'terminos-politicas/:id',
    //     loadComponent: () => import('./pages/terminos-politicas/terminos-politicas.component')
    //         .then((tp) => tp.TerminosPoliticasComponent)
    // },
    // //#########################esta ruta debe estar siempre semifinal###################################################################
    // {
    //     path: ':category/:id_product-:link_rewrite',
    //     loadComponent: () => import('./pages/descripcion-producto/descripcion-producto.component')
    //         .then((q) => q.DescripcionProductoComponent)
    // },
    //#########################esta ruta debe estar siempre al final si o si###################################################################
    /*{
        path: '**',
        redirectTo: 'google-search'
    }*/
];
